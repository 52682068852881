import React from "react"
import { graphql, Link } from "gatsby"
import ListaPrensa from "../components/listaPrensa"
import Layout from "../components/layout"
import Contactanos from "../components/contactanos"
import styled from "styled-components"

const CenteredContent = styled.div`
    text-align: center;
    margin-bottom: 50px;
`

const ContactoPrensaSuccess = ({ location }) => {
    const pageTitle = (
        <span>
            Apariciones en Prensa de
            <br />
            <em>Open Green Road</em>
        </span>
    )

    return (
        <div style={{ backgroundColor: "#f2f2f2" }}>
            <Layout location={location}>
                <CenteredContent>
                    <p>¡Muchas Gracias!</p>
                    <p>Pronto nos pondremos en contacto contigo.</p>
                    <Link to="/prensa">Volver a Prensa</Link>
                </CenteredContent>
            </Layout>
        </div>
    )
}

export default ContactoPrensaSuccess

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
